import React from 'react'

export default function Footer() {
  return (
    <div className='Footer'>
      <div className="bm">
        Designed by <a href="https://birmobil.com" target="_blank" rel="noopener noreferrer">birmobil</a>
      </div>
      <div className='reserved'>All rights reserved by knidosmarina.com.tr</div>
      <div className="contact">
        <img src="/assets/mail.svg" alt="" /> <a href="mailto:info@knidosmarina.com.tr">info@knidosmarina.com.tr</a>
      </div>
    </div>
  )
}
