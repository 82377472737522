import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState, useEffect } from 'react';
import Lading from './components/Lading';

function App() {
  const [page,setPage] = useState("landing")

  useEffect(() => {
    document.title = 'Knidos Marina';
  }, []);

  const renderContent = () => {
    switch(page) {
      default:
        return (
          <Lading/>
        )
    }
  }
  return (
    <div className="App">
      <Header/>
      {renderContent()}
      <Footer/>
    </div>
  );
}

export default App;
