import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import '../lang/conf'
import Glider from 'react-glider';
import '../../node_modules/glider-js/glider.min.css'
import images from '../galeri'

export default function Lading() {
    const { t, i18n } = useTranslation()
    const [img, setImage] = useState()

    const getProje = (p) => {
        const parts = p.split("(p)")
        return (
            <div className='parts'>
                <div>{parts[0]}</div>
                <div>{parts[1]}</div>
            </div>
        )
    }

    useEffect(() => {
    }, [])

    useEffect(() => {
        if(img) {

        }
    }, [img])


    return (
        <div className="Landing">

            {img && 
            
            <div className='outer' onClick={()=>{setImage()}}>
                <div className="img">
                    <img src={img} alt="image" />
                </div>
            </div>
            
            }

            <div className="hero" style={{ backgroundImage: 'url("/assets/hero.jpg")' }}>
                <div className="content">
                    <h1>{t("herotitle")}</h1>
                    <div className="yazi">{t("herotext")}</div>
                    <div className="buttons">
                        <button className='prime' id="iletisim">{t("iletisim")}</button>
                        <button id="detay">{t("pdetay")}</button>
                    </div>
                </div>
            </div>

            <div className="banners">
                <h2>{t("bilesenler")}</h2>
                <Glider
                    draggable={true}
                    slidesToShow={"auto"}
                    itemWidth={300}
                    scrollLock={true}
                >
                    <div className="banner">
                        <img src="/assets/medya.png" alt="" />
                        <span>{t("bannermedia")}</span>
                    </div>
                    <div className="banner">
                        <img src="/assets/doga.png" alt="" />
                        <span>{t("bannerdoga")}</span>
                    </div>
                    <div className="banner">
                        <img src="/assets/fuar.png" alt="" />
                        <span>{t("bannerfuar")}</span>
                    </div>
                    <div className="banner">
                        <img src="/assets/konaklama.png" alt="" />
                        <span>{t("bannerkonak")}</span>
                    </div>
                    <div className="banner">
                        <img src="/assets/yuzme.png" alt="" />
                        <span>{t("banneryuzme")}</span>
                    </div>
                    <div className="banner">
                        <img src="/assets/spor.png" alt="" />
                        <span>{t("bannerspor")}</span>
                    </div>
                </Glider>
            </div>

            <div className="bolge">
                <h2>{t("bolgeizleme")}</h2>
                <div className="video-container">
                    <video src="/assets/drone.mp4" controls></video>
                </div>
            </div>

            <div className="proje">
                <h2>{t("projekisa")}</h2>
                <div className="yonetici">
                    <img src="/assets/ss2.jpg" alt="" />
                </div>
                <div className="text">
                    {getProje(t("projesummary"))}
                    <div className="devami"><a href={"/assets/proje-" + i18n.language + ".pdf"}><button className='dark'>{t("detayindir")}</button></a></div>
                </div>
            </div>

            <div className="Galeri" id="#galerisection">
                <h2>Galeri</h2>
                <div className="images">
                    {images.map((i, index) => {
                        return (
                            <div className="image" key={"image" + index}>
                                <img src={i} alt={i} onClick={()=>{
                                    if(img === i) {
                                        setImage(NaN)
                                    } else {
                                        setImage(i)
                                    }
                                }}/>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}
