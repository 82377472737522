import React, { useEffect, useRef, useState } from 'react'
import '../lang/conf'
import { useTranslation } from 'react-i18next';
import Select from 'react-select'

const diller = [
    { image: '/assets/tr.png', label: 'Türkçe', value:"tr" },
    { image: '/assets/en.png', label: 'English', value:"en" },
    { image: '/assets/de.png', label: 'German', value:"de"  },
  ]


export default function Header() {
    const { t, i18n } = useTranslation();
    const mcheck = useRef()
    const mobilemenu = useRef()
    const [ln, setLn] = useState({})

    useEffect(() => {
      diller.forEach((dil)=>{
        console.log(i18n.language + " " + dil.value)
        console.log(i18n.language.startsWith(dil.value))
        if(i18n.language.startsWith(dil.value)) {
          setLn(dil)
        }
      })
    }, [])
    

    return (
    <div className="Header">
        <div className="container">
            <img className='logo' src="/assets/logo.png" alt="Logo" />
            <div className="menu">
              <label for="mmenu" onClick={()=>{
                if(mcheck.current.checked) {
                  mobilemenu.current.classList.add("mobilemenu")
                } else {
                  mobilemenu.current.classList.remove("mobilemenu")
                }
              }}>☰</label>
              <input type="checkbox" id="mmenu" ref={mcheck}/>
                <ul ref={mobilemenu}>
                    <li><a href="mailto:info@knidosmarina.com.tr" id="iletisim">{t("iletisim")}</a></li>
                    <li><a href="#galerisection" id="galeri">{t("galeri")}</a></li>
                    <li><a href={"/assets/proje-" + i18n.language + ".pdf"} id="proje">{t("pdetay")}</a></li>
                    <li>
                        <Select 
                        value={ln}
                        options={diller} 
                        onChange={(v)=>{
                          i18n.changeLanguage(v.value)
                          setLn(v)
                        }}
                        formatOptionLabel={country => (
                            <div className="country-option">
                              <img src={country.image}/>
                              <span>{country.label}</span>
                            </div>
                          )}
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
